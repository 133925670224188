import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { ArticleTeaser } from '../components/ArticleTeaser';
import { Box } from '../components/common/Box';
import { Grid } from '../components/common/Grid';
import { H1 } from '../components/common/Heading';
import { InnerGrid } from '../components/common/InnerGrid';
import { Text } from '../components/common/Text';
import { Layout } from '../components/Layout';
import { grid } from '../theme/theme';
import { isNotNull } from '../utils/isNotNull';
import { PageProps } from '../utils/PageProps';
import { TagPageQuery } from '../__generated__/graphql';
import { isIE } from 'react-device-detect';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { useThemeContext } from '../components/ThemeChanger';

const TagPage: React.FC<PageProps<TagPageQuery>> = ({ data, pageContext, location }) => {
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const themeCtx = useThemeContext();
  useEffect(() => {
    setNotSupportedBrowser(isIE);
    themeCtx.setTheme('light');
  }, []);

  const page = data.sanityTag!;
  const articles = data.allSanityArticle?.nodes?.filter(isNotNull) ?? [];

  return notSupportedBrowser ? (
    <Layout locale={pageContext.locale} location={location} alternativeLinks={[]}>
      <Grid gridTemplateColumns={grid} data-component="wut">
        <BrowserNotSupported locale={pageContext.locale} />
      </Grid>
    </Layout>
  ) : (
    <Layout locale={pageContext.locale} location={location} alternativeLinks={[]}>
      <Box pb={5}>
        <Grid gridTemplateColumns={grid}>
          <InnerGrid>
            <Box mb={5} gridColumn="1 / -2">
              <H1>{page.title}</H1>
              <Text fontSize={4} fontWeight={300}>
                {articles.length} articles
              </Text>
            </Box>
          </InnerGrid>
          <InnerGrid mt={6}>
            {articles.map((article, i) => {
              const image = article.image?.asset?.gatsbyImageData ?? null;

              return (
                <ArticleTeaser
                  index={i}
                  key={article.id}
                  title={'article.title'}
                  link={'article.slug'}
                  kicker={article.category?.title || 'Stuff'}
                  image={image}
                />
              );
            })}
          </InnerGrid>
        </Grid>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query TagPageQuery($id: String!) {
    sanityTag(_id: { eq: $id }) {
      title
    }
    allSanityArticle(filter: { tags: { elemMatch: { _id: { eq: $id } } } }) {
      nodes {
        id
        heading {
          sv
          en
          dk
        }
        category {
          title
        }
        image {
          asset {
            gatsbyImageData
          }
        }
        slug {
          en {
            current
          }
          sv {
            current
          }
          dk {
            current
          }
        }
      }
    }
  }
`;

export default TagPage;
